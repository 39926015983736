import logo from './logo.svg';
import React, { Component } from 'react';
import './App.css';
import Home from './components/pages/Home';
import Navbar from './components/Navbar';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';

function App() {
 
      return (
         <Router>
            <Navbar />
              <Switch>
                <Route path ='/' exact component = {Home}/>
              </Switch>
         </Router>
      );
  };
export default App;